.AppLayout {
    /* width: 100vw; */
    height: 100vh;
}

.AppContent {
    width: 100vw;
    max-height: 100vh;
    overflow-y: scroll;
}


