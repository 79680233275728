.bg-main {
    background-color: #b70621;
}

.bg-title{
    background-color: #b70621;
    opacity: 0.75;
    border-top-left-radius:8px;
    border-top-right-radius: 8px;
}

.edit {
    cursor: pointer;
}

.text_title{
    color: #fff;
}

.edit:hover {
    background-color: whitesmoke;
    border: 1px solid darkgrey;
    border-radius: 5px;
    padding: 1px 2px 4px 5px;
}

.attachment {
    cursor: pointer;
}

.attachment:hover {
    font-weight: bolder;
    color: #b70621;
}

.center {
    margin: 0;
    position: absolute;
    margin-top: 30px;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.resolve-reject {
    width: 100px;
}

.btn-warning-txt-color{
    color: black !important;
}

.content-detail {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: start;
    height: fit-content;
    font-size: 13px;
    padding: 0 !important;
    margin-right: 0 !important;
    margin-left: 2% !important;
}

.content-detail-prop {
    margin: 0 !important;
    font-size: 13px;
}

.column {
    margin: 0 !important;
    padding: 0 !important;
}