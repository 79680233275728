.commentBox {
    overflow-y: scroll;
    height: 300px;
    display: flex;
    flex-direction: column-reverse;
}

.commentBoxEmpty {
    height: 35px;
    display: flex;
    flex-direction: column-reverse;
    padding: 5px;
    width: 100%;
    text-align: center;
}