.nav-tabs > .nav-item > a {
    color: black;
    cursor: pointer;
}

.edit-category-container {
    display: flex;
    flex-direction: column;
    width: 600px;
    background-color: rgb(255, 255, 255);
    border: 2px solid rgb(255, 255, 255);
    border-radius: 40px;
    display: flex;
    align-items: center;
    padding-bottom: 30px;
    padding-top: 20px;
    padding-left: 5%;
    padding-right: 5%;
    box-shadow: 0px 4px 35px rgba(0, 0, 0, 0.08);
}

.edit-category-flex-container {
    display: flex;
    justify-content: center;
    margin-top: 0px;
}

.underline {
    border-bottom: 3px solid #d7dedd;
    font-size: 5px;
    width: 100%;
    margin-bottom: 5px;
}

.add-user-btn {
    width: 80px;
    margin: 15px;
}

.cancel {
    color: #b70621 !important;
}

.btn-outline-danger {
    color: #b70621 !important;
}

input[type='radio']:after {
    color: #b70621 !important;
}

input[type='radio']:checked:after {
    color: #b70621 !important;
}
