.nav-pills > .active > a {
  background-color: #B70621;
  color: white !important;
  font-weight: bold;
  border-radius: 20px;
}
.nav-pills > .nav-item > a:hover {
  color: black !important;
  font-weight: bold;
  cursor: pointer;
}

.nav-pills > .nav-item > a {
    color: #7D96B3;
}

a {
  cursor: pointer;
}

a:hover {
  color: black !important;
  font-weight: bold;
  cursor: pointer;
}


.AppSidebar {
  position: sticky !important;
  left: 0 !important;
  width: 16.5rem; height:100%; background-color:#F5F7FA;
}

.img-sidebar {
  position: relative !important;
}