.body {
    position: absolute;
    height: 100%;
    width: 50%;
    margin: 0;
    padding: 0;
    background-image: url('../assest/login-background.svg');
    background-repeat: no-repeat;
    background-position: center;
    height: 100%;
}

img {
    position: absolute;
    top: 5%;
    left: 2%;
}

.login-background {
    background-color: #f3e8e5;
    position: absolute;
    right: 0px;
    width: 50%;
}

.flex-container {
    display: flex;
    /* flex-direction: row-reverse; */
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.app-title {
    color: rgb(255, 255, 255);
    margin-left: 128px;
    margin-bottom: 64px;
    flex-grow: 1;
}

.title {
    font-size: 64px;
}

.subtitle {
    font-size: 32px;
}

.login-container {
    display: flex;
    flex-direction: column;
    width: 370px;
    background-color: rgb(255, 255, 255);
    border: 2px solid rgb(255, 255, 255);
    border-radius: 40px;
    /* margin-right: 20%; */
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 30px;
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;
    box-shadow: 0px 4px 35px rgba(0, 0, 0, 0.08);
}

.login-form {
    flex-grow: 1;
    margin: 0;
    width: 100%;
    justify-content: space-around;
    gap: 15px;
    padding-top: 0;
    padding-left: 5%;
    padding-right: 5%;
}

.login-form-item {
    /* position: relative; */
    /* flex-grow: 1;
    width: 90%;
    justify-content: space-around;
    gap: 5px;
    text-align: left;
    align-items: stretch; */
    display: flex;
    flex-direction: column;
    min-width: 0;
    flex-grow: 1;
    text-align: left;
    display: flex;  
    align-items: stretch;
}

.contain {
    width: 100%;
}

/* .button {
    width: 100%;
    display: block;
} */

.input {
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    min-width: 100%;
    padding: 5%;
    border-radius: 5px;
}

.error {
    color: red;
    display: flex;
    text-align: left !important;
}

.login-header {
    margin: 0;
    align-items: center;
}

.get-otp-btn {
    width: 100%;
    border-radius: 10px !important;
}

.desc {
    color: rgb(119, 118, 118);
}

.email-verify {
    color: #b70621;
}

.btn-danger {
    background-color: #b70621 !important;
    border-color: #f3eed9 !important;
}
.btn-danger:hover {
    background-color: #d80020 !important;
}
.btn-danger:active,
.btn-danger:visited,
.btn-danger:focus {
    background-color: #b70621 !important;
    border-color: #f3eed9 !important;
    box-shadow: #f3e8e5;
}

.ellipse {
    position: relative;
    background-color: #b70621;
    height: 80px;
    width: 80px;
    border-radius: 100%;
    top: -30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
