.nav-tabs > .nav-item > a {
    color: black;
    cursor: pointer;
}

.btn-update {
    color: black !important;
    background-color: #37cbf0 !important;
    border: 2px solid #37cbf0 !important;
}

.btn-update:hover {
    border: 2px solid #37cbf0 !important;
    background-color: #ffffff80 !important;
}

.btn-delete {
    color: #f03738 !important;
    background-color: #ffffff80 !important;
    border: 2px solid #f03738 !important;
}

.btn-delete:hover {
    border: 2px solid #f03738 !important;
    background-color: #f03738 !;
}

.btn-add-user {
    color: white !important;
    background-color: #6a6a6a !important;
    border: 2px solid #6a6a6a !important;
}

.btn-add-user:hover {
    background-color: #ffffff !important;
    border: 2px solid #6a6a6a !important;
    color: #6a6a6a !important;
}

.btn-icons {
    border: none !important;
}

.changeColorEdit {
    color: #37cbf0;
}

.changeColorEdit:hover {
    color: #6a6a6a;
}

.changeColorDelete {
    color: #f03738;
}

.changeColorDelete:hover {
    color: #6a6a6a;
}

.search-form-user {
    width: 150px !important;
}
