.dropdown {
    position: relative;
    display: inline-block;
}

.dropdown:hover {
    cursor: pointer;
}
  
.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    width: max-content;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    padding: 12px 16px;
    z-index: 1;
    border-radius: 9px;
}
  
.dropdown:hover .dropdown-content {
    display: block;
    cursor: pointer;
}

.dropdown-content p {
    color: black;
    text-decoration: none;
    display: block;
}
  
.dropdown-content p:hover {
    background: rgba(183, 6, 33, 0.2);
    border-radius: 9px;
    height: 35px;
    align-items: center;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 170px;
}