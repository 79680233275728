.otp-expired-container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    /* background-color: rgba(85, 85, 85, 0.4); */
    height: 100%;
    overflow: auto;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    flex-direction: column;
    gap: 10px;
    z-index: 100;
    backdrop-filter: blur(3px);
}

.unregistered-email-content {
    width: 360px;
    height: 270px;
    background-color: rgb(255, 255, 255);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    padding: 0%;
    padding-bottom: 15px;
    text-align: center;
}

.x {
    display: flex;
    margin-left: 90%;
    flex-direction: row;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
    gap: 15px;
    border-radius: 100%;
    background-color: #b70621;
    width: 30px;
    height: 30px;
    color: white;
    border: none;
}

.btn {
    color: white !important;
}

.btn:hover {
    color: black !important;
    border: none;
}
