.create-text {
    font-size: x-large;
    font-weight: bold;
    color: #B70621;
}

.description {
    width: 100%;
}

.bg-title-ct{
    background-color: #b70621;
    opacity: 0.75;
    border-top-left-radius:8px;
    border-top-right-radius: 8px;
}

.input-create-ticket{
    width: 170px !important;
}

@media screen and (max-width: 320px) {
    .input-create-ticket{
        width: 140px !important;
    }
}