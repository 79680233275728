h2 {
    color: #B70621;
    font-weight: bolder !important;
}

.btn {
    border: none;
}

.btn:hover {
    border: none;
}

.appHeader {
    position: sticky;
    background-color: white;
    top: 0;
    z-index: 100;
}

.modalNotif {
    position: absolute;
    width: 250px;
    font-size: small;
    top: 40px;
    right: 10px;
    display: flex;
    flex-direction: column;
    text-align: start;
    background-color: #F0F3F7;
    border-radius: 10px;
    z-index: 100;
}

.container-header-left {
    display: flex;
    width: 50vw;
    flex-direction: row;
    align-items: center;
}