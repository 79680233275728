html, body {
    height: 100%;
    margin: 0;
  }

.warn-add-user-container-success {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(85, 85, 85, 0.4);
    min-height: 100%;
    max-height: 100%;
    overflow: auto;
    overflow-y: scroll;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    flex-direction: column;
    gap: 10px;
    z-index: 102;
    backdrop-filter: blur(3px);
}

.warn-add-user-content-success {
    width: 360px;
    height: 200px;
    background-color: rgb(255, 255, 255);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    padding: 1px;
    padding-top: 150px;
    padding-bottom: 100px;
    text-align: center;
}

.btn {
    color: white !important;
}

.btn:hover {
    color: black !important;
    border: none;
}

.success-ellips {
    position: relative;
    background-color: #13c39c;
    height: 140px;
    width: 140px;
    border-radius: 100%;
    top: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 5px solid #25ffae;
}

.buttons {
    position: relative;
    display: inline-flex;
    flex-flow: row wrap;

    margin: 0;
    padding: 0;
}

.success-icon {
    position: absolute;
    top: 4%;
}

.warning-add-user-btn {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
}

.success-user-btn {
    width: 150px;
    margin-left: 5px;
    margin-right: 5px;
    background-color: #13c39c !important;
}
.success-user-btn:hover {
    background-color: #25ffae !important;
    color: white !important;
}

.add-user-btn-left {
    width: 90px;
    margin-left: 0px;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.add-user-btn-right {
    width: 90px;
    margin-left: 10px;
    margin-right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.btn-outline-danger {
    color: #b70621 !important;
}