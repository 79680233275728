.nav-tabs > .nav-item > a {
    color: black;
    cursor: pointer;
}

.btn-update {
    color: black  !important;
    background-color: #37cbf0 !important;
    border: 2px solid #37cbf0 !important;
}

.btn-update:hover {
    border: 2px solid #37cbf0 !important;
    background-color: #ffffff80 !important;
}

.btn-delete {
    color: #F03738 !important;
    background-color: #FFFFFF80 !important;
    border: 2px solid #F03738 !important;
}

.btn-delete:hover {
    border: 2px solid #F03738 !important;
    background-color: #F03738 !important;
}

.btn-add-department {
    color: white !important;
    background-color: #6A6A6A !important;
    border: 2px solid #6A6A6A !important;
}

.btn-add-department:hover {
    background-color: #ffffff !important;
    border: 2px solid #6A6A6A !important;
    color: #6A6A6A !important;
}