.otp-verify-container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(85, 85, 85, 0.4);
    height: 100%;
    overflow: auto;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    flex-direction: column;
    gap: 10px;
    z-index: 100;
    backdrop-filter: blur(3px);
}

.otp-verify-content {
    width: 360px;
    height: 270px;
    /* height: 320px; */
    background-color: rgb(255, 255, 255);
    display: inline-block;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    padding: 10px;
}

.x {
    /* display: flex; */
    /* margin-left: 90%; */
    /* flex-direction: column; */
    /* flex-grow: 1;
    justify-content: center;
    align-items: center; */
    /* gap: 15px; */
    width: 30px;
    height: 30px;
    border-radius: 100%;
    background-color: #b70621;
    color: white;
    border: none;
}

.btn {
    color: white !important;
}

.btn:hover {
    color: black !important;
    border: none;
}

/* .btn-x:hover {
    border-radius: 100%;
} */

.otp {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
}

.box {
    margin: 10%;
    border: 2px solid black;
    border-radius: 15px;
    padding: 5% 10% 5%;
    background-color: rgb(255, 255, 255);
}

.desc {
    color: rgb(119, 118, 118);
    text-align: center;
    font-size: small;
}

.otp-input {
    border: none;
}

.otp-input:hover,
.otp-input:active,
.otp-input:visited,
.otp-input:focus {
    border: none !important;
}

.box2 {
    size: 10px;
    border-radius: 15px;
    /* padding: 5% 10% 5%; */
    background-color: rgb(255, 255, 255);
}

.error {
    color: red;
}

.otp-input input {
    border: 0px solid rgb(102, 102, 102);
    background: #f3e8e5;
    width: 40px !important;
    height: 40px;
    margin: 5px !important;
    padding: 0.9rem 1rem;
    border-radius: 100%;
}

a:link,
a:visited,
a:active {
    text-decoration: none;
    color: #b70621;
}

a:hover {
    color: red !important;
    text-decoration: none;
}
