.nav-tabs > .nav-item > a {
  color: black;
  cursor: pointer;
}

h4 {
  color: #b70621;
  font-weight: bold !important;
}

.detail {
  color: white !important;
}

.search-form {
  width: 220px !important;
  height: fit-content !important;
}

.sort-btn {
  height: fit-content !important;
}

.btn-search {
  background-color: #7066e0 !important;
}
