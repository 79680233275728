table,
td {
    border: 1px solid;
}

th {
    border-bottom: 2px solid rgb(136, 136, 136) !important;
    border-top: 2px solid rgb(150, 150, 150) !important;
}

.feature-head {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    padding: 0;
}

.feature-head-item {
    display: flex;
    margin-left: 0;
    margin-right: 0;
}

.table-title {
    display: flex;
    align-items: center;
    size: 50px;
    color: #b70621;
    margin: 0;
    font-weight: bold !important
}

.add-department {
    float: right;
}

.pagination-flex {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
