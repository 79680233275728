.nav-tabs > .nav-item > a {
    color: black;
    cursor: pointer;
}

.add-user-container-ori {
    display: flex;
    flex-direction: column;
    width: 550px;
    background-color: rgb(255, 255, 255);
    border: 2px solid rgb(255, 255, 255);
    border-radius: 40px;
    /* margin-right: 20%; */
    display: flex;
    align-items: center;
    /* justify-content: center; */
    padding-bottom: 30px;
    padding-top: 20px;
    padding-left: 5%;
    padding-right: 5%;
    box-shadow: 0px 4px 35px rgba(0, 0, 0, 0.08);
}

.add-user-flex-container-ori {
    display: flex;
    /* width: 800px; */
    /* flex-direction: row-reverse; */
    justify-content: center;
    /* align-items: center;  */
    margin-top: 0px;
    /* height: 100%; */
}

.underline {
    border-bottom: 3px solid #d7dedd;
    font-size: 5px;
    width: 100%;
    margin-bottom: 5px;
}

.role {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* align-self: center; */
    width: 100%;
}

.role-item {
    flex-basis: 100%;
    margin-left: 25px;
    margin-right: 25px;
    /* text-align: left; */
}

.department {
    /* display: flex; */
    /* flex-direction: column;
    text-align: left;
    width: 100%; */
    margin-bottom: 20px;
    /* display: flex;
    justify-content: center;
    align-items: center; */
}

.department-dropdown {
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 5px;
    border-radius: 5px;
    text-align: left;
    display: flex;
    justify-content: center;
    align-items: center;
}

.add-user-btn {
    width: 80px;
    margin: 15px;
}

.cancel {
    color: #b70621 !important;
}

.btn-outline-danger {
    color: #b70621 !important;
}

input[type='radio']:after {
    color: #b70621 !important;
}

input[type='radio']:checked:after {
    color: #b70621 !important;
}
